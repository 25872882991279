import useAnalytics from '@/components/hooks/useAnalytics';
import AboutNavigationBar from '@/components/navigation_bars/AboutNavigationBar';
import config from '@/lib/config';
import { ErrorPageEvents } from '@/lib/types/mixpanel-events';
import { Button } from '@nextui-org/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';

export default function NotFound() {
    const router = useRouter();
    const { track } = useAnalytics();

    useEffect(() => {
        track({
            eventName: ErrorPageEvents.loaded,
            eventType: 'load'
        });
    }, [track]);

    const onHomeClick = () => {
        track({
            eventName: ErrorPageEvents.homeClicked,
            eventType: 'click'
        });

        router.push('/landing');
    };

    return (
        <>
            <AboutNavigationBar />
            <Container>
                <Title>404</Title>
                <Subtitle>הדף לא נמצא</Subtitle>
                <Text>הדף שחיפשת לא קיים</Text>
                <LinksContainer>
                    <StyledButton onClick={onHomeClick}> חזרה לדף הבית</StyledButton>
                </LinksContainer>
            </Container>
        </>
    );
}
const Container = styled.div`
    margin-block-start: ${config.ui.navbarHeight}px;

    height: calc(100dvh - ${config.ui.navbarHeight}px);
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    direction: rtl;
`;

const Title = styled.h1`
    font-size: 10.5rem;
    font-weight: bold;
    margin-block-end: 20px;
`;

const Subtitle = styled.h1`
    font-size: 2.5rem;
    font-weight: bold;
    margin-block-end: 20px;
`;

const Text = styled.p`
    font-size: 1.2rem;
    margin-block-end: 20px;
    color: #333;
`;

const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
`;

const StyledButton = styled(Button)`
    background-color: black;
    color: white;
    padding: 24px;
    font-size: 18px;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }

    &:active {
        opacity: 1;
    }
`;
