import { styled } from 'styled-components';
import HNButton from '../HNButton';
import { useRouter } from 'next/router';
import { Fonts } from '@/styles/fonts';
import { Image } from '@nextui-org/react';
import config from '@/lib/config';
import { colors } from '@/styles/colors';
import { Secular_One } from 'next/font/google';
import { ReactNode, useEffect, useRef } from 'react';

const secularOne = Secular_One({
    subsets: ['hebrew'],
    weight: '400'
});

const STATIC_URL = 'https://static.hazmanina.co.il';
const LOGO_URL = `${STATIC_URL}/landing/smiling-letter.webp`;

const NavigationBar = ({ children, className }: { children: ReactNode; className?: string }) => {
    const router = useRouter();
    const ref = useRef<HTMLDivElement>(null);

    const scrollToElement = () => {
        const { current } = ref;
        if (current !== null) {
            current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(scrollToElement, []);

    const onHomeClick = () => {
        router.push('/');
    };

    return (
        <Container ref={ref} className={className}>
            <InnerContainer>
                <LeftContainer>
                    <ButtonsContainer>{children}</ButtonsContainer>
                </LeftContainer>
                <RightContainer>
                    <HNButton style="nav-logo" onClick={onHomeClick} font={Fonts.Alef}>
                        <InnerButtonContainer>
                            <StyledLogoText className={secularOne.className}>הזמנינא</StyledLogoText>
                            <StyledLogo src={LOGO_URL} alt="hazmanina" width={50} height={50} />
                        </InnerButtonContainer>
                    </HNButton>
                </RightContainer>
            </InnerContainer>
        </Container>
    );
};

const Container = styled.div`
    z-index: 20;
    position: fixed;
    top: 0;

    height: ${config.ui.navbarHeight}px;
    width: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: ${colors.primary};
    align-items: center;

    @media screen and (min-width: 2000px) {
        border-radius: 0 0 6px 6px;
    }
`;

const InnerContainer = styled.div`
    height: 100%;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 900px) {
        width: calc(100% - 8px);
    }
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-inline-start: 20px;
    gap: 60px;

    @media screen and (max-width: 900px) {
        gap: 30px;
    }
`;

const InnerButtonContainer = styled.div`
    direction: ltr;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const StyledLogo = styled(Image)`
    margin-inline-end: 10px;

    @media screen and (max-width: 900px) {
        width: 48px;
        height 48px;
    }
`;

const StyledLogoText = styled.div`
    line-height: 1;
    margin-inline-end: 10px;
    color: white;

    @media screen and (max-width: 900px) {
        display: none;
    }
`;

export default NavigationBar;
